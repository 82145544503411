import { useCountdown } from '@shared/hooks';
import { FC, useEffect, useState } from 'react';
import { styles } from './countdown.styles';

export const Countdown: FC = () => {
  const { days, hours, minutes, seconds } = useCountdown(1719525600000);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setIsReady(true);
  }, []);

  if (!isReady) return null;

  return (
    <div css={styles.base}>
      <div css={styles.item}>
        <span>{days}</span>
        <span css={styles.label}>Dana</span>
      </div>
      <span css={styles.colon}>:</span>
      <div css={styles.item}>
        <span>{hours}</span>
        <span css={styles.label}>Sati</span>
      </div>
      <span css={styles.colon}>:</span>
      <div css={styles.item}>
        <span>{minutes}</span>
        <span css={styles.label}>Minuta</span>
      </div>
      <span css={styles.colon}>:</span>
      <div css={styles.item}>
        <span>{seconds}</span>
        <span css={styles.label}>Sekundi</span>
      </div>
    </div>
  );
};
