import { SerializedStyles } from '@emotion/react';
import { FormikTouched } from 'formik';
import HidenIcon from 'public/icons/hidden-16.svg';
import ShowIcon from 'public/icons/show-16.svg';
import React, { FC, useRef, useState } from 'react';
import { formInputStyles } from './styles';

interface Props<T> extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  isRequired?: boolean;
  error?: string;
  Icon?: React.ComponentType<{
    viewBox?: string;
    css?: SerializedStyles;
  }>;
  isPasswordInput?: boolean;
  touched?: FormikTouched<T>;
}

export const FormInput: FC<Props<any>> = ({
  label,
  isRequired,
  Icon,
  error,
  isPasswordInput,
  touched,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const inputType = isPasswordInput && !isPasswordVisible ? 'password' : 'text';

  function togglePasswordVisibility() {
    setIsPasswordVisible(!isPasswordVisible);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  return (
    <div css={formInputStyles.wrapper}>
      {label && (
        <label css={formInputStyles.label}>
          {label}
          {isRequired && <span css={formInputStyles.labelStar}>*</span>}
        </label>
      )}
      <div css={formInputStyles.inputWrapper}>
        <input
          css={Icon ? formInputStyles.base : formInputStyles.baseWithoutIcon}
          {...props}
          type={inputType}
          ref={inputRef}
        />
        {Icon && <Icon css={formInputStyles.icon} />}
        {isPasswordInput && !isPasswordVisible && (
          <HidenIcon
            css={formInputStyles.showIcon}
            onClick={togglePasswordVisibility}
          />
        )}
        {isPasswordInput && isPasswordVisible && (
          <ShowIcon
            css={formInputStyles.showIcon}
            onClick={togglePasswordVisibility}
          />
        )}
      </div>

      {error && touched[props.name] && (
        <span css={formInputStyles.errorMessage}>{error}</span>
      )}
    </div>
  );
};
