import { css } from '@emotion/react';

const wrapper = css`
  position: relative;
  width: 100%;
`;

const base = css`
  width: 100%;
  padding: 12px 16px 12px 44px;
  border-radius: 10px;
  background-color: var(--color-background-4);
  color: var(--color-text-6);
  cursor: pointer;
  border: 1px solid transparent;

  ::placeholder {
    color: var(--color-text-3);
    font-size: 16px;
    line-height: 24px;
  }

  &:focus,
  &:active,
  &:focus-visible,
  &:focus-within {
    border: 1px solid #61645e;
  }
`;

const baseWithoutIcon = css`
  ${base};
  padding: 12px 16px;
`;

const label = css`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: var(--color-text-4);
`;

const labelStar = css`
  color: #acef34;
`;

const icon = css`
  position: absolute;
  left: 17px;
  top: 14px;
`;

const showIcon = css`
  position: absolute;
  right: 17px;
  top: 14px;
  cursor: pointer;
`;

const errorMessage = css`
  position: absolute;
  left: 0;
  color: var(--color-error);
  font-size: 12px;
  line-height: 20px;
  margin-top: 4px;
`;

const inputWrapper = css`
  height: 44px;
  margin-top: 8px;
  position: relative;
`;

export const formInputStyles = {
  base,
  baseWithoutIcon,
  wrapper,
  label,
  icon,
  showIcon,
  errorMessage,
  labelStar,
  inputWrapper,
};
