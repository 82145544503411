import { css } from '@emotion/react';
import { breakpoints } from '@styles/variables.styles';

const base = css`
  padding: 8px;
  border: 1px solid var(--color-border-cardBorder);
  width: max-content;
  font-family: var(--font-family-primaryBold);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  display: flex;
  gap: 20px;
  color: var(--color-text-6);
  margin-bottom: 24px;

  @media ${breakpoints.fromSml} {
    padding: 12px;
    border-radius: 12px;
    font-size: 24px;
    line-height: 32px;
  }

  @media ${breakpoints.fromMed} {
    grid-column: 8 / 41;
    padding: 32px;
    border-radius: 20px;
    font-size: 40px;
    line-height: 44px;
    letter-spacing: -0.02em;
  }
`;

const item = css`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
`;

const label = css`
  font-family: var(--font-family-primary);
  letter-spacing: 0.2em;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;

  @media ${breakpoints.fromSml} {
    font-size: 14px;
    line-height: 20px;
  }

  @media ${breakpoints.fromMed} {
    font-size: 16px;
    line-height: 24px;
  }
`;

const colon = css`
  color: var(--color-icon-4);
`;

export const styles = { base, item, label, colon };
