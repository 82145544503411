import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import XIcon from 'public/icons/X-16.svg';
import FacebookIcon from 'public/icons/facebook-16.svg';
import InstagramIcon from 'public/icons/instagram-16.svg';
import LinkedInIcon from 'public/icons/linkedin-24.svg';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { styles } from '../styles';

interface Props {
  isActive: boolean;
  navigation: JSX.Element;
  changeState: () => void;
}

export const Hamburger: FC<Props> = ({ isActive, navigation, changeState }) => {
  const pathname = usePathname();
  const navStyle = (path: string) => {
    if (pathname === path) return styles.navigationItemActive;

    return styles.navigationItem;
  };

  return (
    <>
      <button
        onClick={changeState}
        className="relative 992:hidden bg-transparent border-none h-16 w-20 z-20"
      >
        <motion.div
          initial={{ rotate: 0, y: 0 }}
          animate={{
            rotate: isActive ? 45 : 0,
            y: isActive ? 10 : 0,
          }}
          transition={{ duration: 0.3, ease: [0.77, 0.0, 0.175, 1.0] }}
          className="top-0 left-0 absolute w-20 h-1 bg-icon-6"
        />

        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: isActive ? 0 : 1 }}
          transition={{ duration: 0.3, ease: [0.77, 0.0, 0.175, 1.0] }}
          className="top-7 right-0 absolute w-[13px] h-1 bg-icon-6"
        />

        <motion.div
          initial={{ rotate: 0, y: 0 }}
          animate={{
            rotate: isActive ? -45 : 0,
            y: isActive ? -5 : 0,
          }}
          transition={{ duration: 0.3, ease: [0.77, 0.0, 0.175, 1.0] }}
          className="bottom-0 left-0 absolute w-20 h-1 bg-icon-6"
        />
      </button>
      <AnimatePresence>
        {isActive && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, filter: ['blur(40px)', 'blur(0px)'] }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: [0.77, 0.0, 0.175, 1.0] }}
            className="992:hidden fixed top-0 left-0 w-[100dvw] h-[100dvh] bg-background-2 z-10 pt-56 pb-32 flex flex-col "
          >
            {navigation}
            <nav className="mt-24 flex flex-col">
              <Link
                css={navStyle('/why-playoff')}
                href="/why-playoff"
                scroll={false}
              >
                <FormattedMessage id="header.why" />
              </Link>
              <Link css={navStyle('/partners')} href="/partners" scroll={false}>
                <FormattedMessage id="header.partners" />
              </Link>
              <Link
                css={navStyle('/directory')}
                href="/directory"
                scroll={false}
              >
                <FormattedMessage id="header.directory" />
              </Link>
              <Link css={navStyle('/contact')} href="/contact" scroll={false}>
                <FormattedMessage id="header.contact" />
              </Link>
            </nav>
            <ul className="flex gap-8 mt-auto px-16">
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/theplayoffapp"
                  className="group w-24 h-24 md:w-40 md:h-40 bg-[#F4F8F70D] rounded-lg flex items-center justify-center"
                >
                  <FacebookIcon
                    className="group-hover:[&>path]:fill-primary [&>path]:transition-all [&>path]:duration-150 [&>path]:ease-out w-16 h-16 md:w-24 md:h-24"
                    viewBox="0 0 16 16"
                  />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/theplayoff.app/"
                  className="group w-24 h-24 md:w-40 md:h-40 bg-[#F4F8F70D] rounded-lg flex items-center justify-center"
                >
                  <InstagramIcon
                    className="group-hover:[&>path]:fill-primary [&>path]:transition-all [&>path]:duration-150 [&>path]:ease-out w-16 h-16 md:w-24 md:h-24"
                    viewBox="0 0 16 16"
                  />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://x.com/theplayoffapp"
                  rel="noopener noreferrer"
                  className="group w-24 h-24 md:w-40 md:h-40 bg-[#F4F8F70D] rounded-lg flex items-center justify-center"
                >
                  <XIcon
                    className="group-hover:[&>path]:fill-primary [&>path]:transition-all [&>path]:duration-150 [&>path]:ease-out w-16 h-16 md:w-24 md:h-24"
                    viewBox="0 0 16 16"
                  />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/theplayoffapp/"
                  className="group w-24 h-24 md:w-40 md:h-40 bg-[#F4F8F70D] rounded-lg flex items-center justify-center"
                >
                  <LinkedInIcon
                    className="group-hover:[&>path]:fill-primary [&>path]:transition-all [&>path]:duration-150 [&>path]:ease-out w-16 h-16 md:w-24 md:h-24"
                    viewBox="0 0 24 24"
                  />
                </a>
              </li>
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
