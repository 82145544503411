import { AnimatedText } from '@ui/AnimatedText';
import { animations } from 'const';
import { motion } from 'framer-motion';
import Link from 'next/link';
import Logo from 'public/logo/playoff-logo-108.svg';
import Logo2 from 'public/logo/playoff-logo-68.svg';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { styles } from './styles';

export const Contact: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <section id="contact" css={styles.base}>
      <div css={styles.wrapper}>
        <div css={styles.container}>
          <motion.div {...animations.opacityInView({})}>
            <Logo2 css={styles.logo2} />
            <Logo css={styles.logo} />
          </motion.div>
          <AnimatedText
            className="flex justify-center text-text-6 text-3xlarge-fluid -tracking-[0.03em] font-bold mb-32 text-center"
            text={formatMessage({ id: 'why-playoff.contact.title' })}
            delay={0.2}
            wrapperElement="h2"
          />
          <AnimatedText
            className="flex justify-center text-text-5 text-base"
            text={formatMessage({ id: 'why-playoff.contact.subtitle' })}
            delay={0.4}
            wrapperElement="p"
          />
          <motion.div
            css={styles.buttonContainer}
            {...animations.opacityInView({ duration: 1, delay: 0.6 })}
          >
            <Link href="/contact" css={styles.contactButton}>
              <FormattedMessage id="why-playoff.contact.contact-button" />
            </Link>
          </motion.div>
        </div>
      </div>
    </section>
  );
};
