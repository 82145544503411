import { motion, useInView } from 'framer-motion';
import React, { useEffect, useMemo, useRef, useState } from 'react';

type MarginValue = `${number}${'px' | '%'}`;

type MarginType =
  | MarginValue
  | `${MarginValue} ${MarginValue}`
  | `${MarginValue} ${MarginValue} ${MarginValue}`
  | `${MarginValue} ${MarginValue} ${MarginValue} ${MarginValue}`;

interface Props {
  text: string;
  className?: string;
  delay?: number;
  amount?: number;
  duration?: number;
  margin?: MarginType;
  wrapperElement?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
}

function getDelayArray(ref: React.RefObject<HTMLElement>) {
  if (!ref.current) return;

  const paragraphLength = ref.current.offsetWidth || 0;
  const words = ref.current.getElementsByClassName('word');
  const delays: number[] = [];
  let currentDelay = 0;
  let length = 0;

  for (let i = 0; i < words.length; i++) {
    const wordLength = words[i].getBoundingClientRect().width;
    length += wordLength;

    if (length > paragraphLength) {
      currentDelay += 0.2;
      length = wordLength;
      delays.push(currentDelay);
      continue;
    }

    delays.push(currentDelay);
  }

  return delays;
}

export const AnimatedText: React.FC<Props> = ({
  text,
  className,
  delay = 0,
  amount = 0.3,
  duration = 1.3,
  margin = '0px 0px -20% 0px',
  wrapperElement: WrapperElement = 'span',
}) => {
  const textArray = text.split(' ');
  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
    amount,
    margin,
  });
  const isLastItem = (index: number) => index !== textArray.length - 1;
  const [delayArray, setDelayArray] = useState<number[]>([]);
  const isLoadedDelayArray = useMemo(
    () =>
      Boolean(delayArray.length) &&
      delayArray.every((item) => typeof item === 'number' && !isNaN(item)),
    [delayArray, text],
  );

  useEffect(() => {
    setTimeout(() => {
      setDelayArray(getDelayArray(ref) || []);
    }, 100);
    /**Delay is needed for translations to load */

    if (!window) return;

    window.addEventListener('resize', () =>
      setDelayArray(getDelayArray(ref) || []),
    );

    return () => {
      window.removeEventListener('resize', () =>
        setDelayArray(getDelayArray(ref) || []),
      );
    };
  }, [ref, text]);

  return (
    <WrapperElement className={`flex w-full flex-wrap ${className}`} ref={ref}>
      {textArray.map((text, i) => (
        <span className="word flex overflow-clip-path" key={i}>
          <motion.span
            initial={{ y: '140%' }}
            animate={{
              y: isInView && isLoadedDelayArray ? 0 : '140%',
            }}
            transition={{
              duration,
              ease: [0.65, 0.01, 0.02, 0.98],
              delay: isLoadedDelayArray ? delay + delayArray[i] : 0,
            }}
          >
            {text}
          </motion.span>
          {isLastItem(i) && <span className="block">&nbsp;</span>}
        </span>
      ))}
    </WrapperElement>
  );
};
