import { css } from '@emotion/react';
import { button } from '@styles/button.styles';
import { typo } from '@styles/typo.styles';
import { breakpoints } from '@styles/variables.styles';

const base = css`
  margin: 0 auto;
  padding: 120px 16px 68px 16px;
`;

const wrapper = css`
  background-color: var(--color-background-4);
  max-width: unset;
  padding: 134px 0;
  border-radius: 20px;

  @media ${breakpoints.fromLrg} {
    padding: 132px 0 192px;
  }
`;

const container = css`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    ${typo.heading1}
    color: var(--color-text-6);
    font-weight: 700;
    margin-bottom: 32px;
    max-width: 700px;
    text-align: center;
  }

  p {
    ${typo.from16to14}
    color: var(--color-text-4);
    max-width: 296px;
    text-align: center;

    @media ${breakpoints.fromLrg} {
      max-width: 448px;
    }
  }
`;

const buttonContainer = css`
  display: flex;
  margin-top: 48px;
  gap: 12px;
`;

const contactButton = css`
  ${button.primary}
  ${typo.from16to14}
  background: linear-gradient(89.8deg, #AEEE39 -8.45%, #62F467 109.75%);
  padding: 8px 36px;

  & > span {
    ${typo.from16to14}
  }
  &:hover,
  &:focus {
    color: black;
    opacity: 1;
  }

  @media ${breakpoints.fromLrg} {
    padding: 12px 80px;
  }
`;

const logo = css`
  display: none;
  cursor: pointer;
  margin-bottom: 12px;

  @media ${breakpoints.fromMed} {
    display: block;
    margin-bottom: 40px;
  }
`;

const logo2 = css`
  display: block;
  cursor: pointer;
  margin-bottom: 12px;

  @media ${breakpoints.fromMed} {
    margin-bottom: 40px;
    display: none;
  }
`;

export const styles = {
  base,
  wrapper,
  container,
  buttonContainer,
  contactButton,
  logo,
  logo2,
};
