import { css } from '@emotion/react';

/**
 * Typo values
 */

export const typo = {
  heading1: css`
    font-size: clamp(2.25rem, 1.5rem + 3.33vw, 4.5rem);
    line-height: clamp(2.5rem, 1.83rem + 2.96vw, 4.5rem);
  `,
  heading2: css`
    font-size: clamp(1.375rem, 0.83rem + 2.41vw, 3rem);
    line-height: clamp(1.75rem, 1.16rem + 2.59vw, 3.5rem);
  `,
  heading3: css`
    font-size: clamp(1.25rem, 0.83rem + 1.85vw, 2.5rem);
    line-height: clamp(1.375rem, 0.83rem + 2.41vw, 3rem);
  `,
  //deleted h4 to h6 for now
  body1: css`
    font-size: clamp(1rem, 0.66rem + 1.48vw, 2rem);
    line-height: clamp(1.5rem, 1rem + 2.22vw, 3rem);
  `,
  body2: css`
    font-size: clamp(0.875rem, 0.58rem + 1.3vw, 1.75rem);
    line-height: clamp(1.25rem, 0.83rem + 1.85vw, 2.5rem);
  `,
  body3: css`
    font-size: clamp(0.875rem, 0.58rem + 1.3vw, 1.75rem);
    line-height: clamp(1rem, 0.66rem + 1.48vw, 2rem);
  `,
  body4: css`
    font-size: clamp(0.75rem, 0.5rem + 1.1vw, 1.5rem);
    line-height: clamp(1.25rem, 0.83rem + 1.85vw, 2.5rem);
  `,
  body5: css`
    font-size: clamp(0.75rem, 0.5rem + 1.1vw, 1.5rem);
    line-height: clamp(1rem, 0.66rem + 1.48vw, 2rem);
  `,
  uppercase: css`
    text-transform: uppercase;
  `,
  lowercase: css`
    text-transform: lowercase;
  `,
  from112to40: css`
    font-size: clamp(2.5rem, 1.1935rem + 5.8065vw, 7rem);
    line-height: clamp(2.75rem, 1.7339rem + 4.5161vw, 6.25rem);
  `,
  from68to36: css`
    font-size: clamp(2.25rem, 1.67rem + 2.58vw, 4.25rem);
    line-height: clamp(2.25rem, 1.67rem + 2.58vw, 4.25rem);
  `,

  from60to40: css`
    font-size: clamp(2.5rem, 2.1371rem + 1.6129vw, 3.75rem);
    line-height: clamp(2.75rem, 2.3145rem + 1.9355vw, 4.25rem);
  `,
  from60to32: css`
    font-size: clamp(2rem, 1.4919rem + 2.2581vw, 3.75rem);
    line-height: clamp(2.25rem, 1.6694rem + 2.5806vw, 4.25rem);
  `,
  from56to32: css`
    font-size: clamp(2rem, 1.56rem + 1.94vw, 3.5rem);
    line-height: clamp(2rem, 1.56rem + 1.94vw, 3.5rem);
  `,
  from40to24: css`
    font-size: clamp(1.5rem, 1.21rem + 1.29vw, 2.5rem);
    line-height: clamp(1.5rem, 1.21rem + 1.29vw, 2.5rem);
  `,

  from32to20: css`
    font-size: clamp(1.25rem, 1.0323rem + 0.9677vw, 2rem);
    line-height: clamp(1.25rem, 1.0323rem + 0.9677vw, 2rem);
  `,

  from24to20: css`
    font-size: clamp(1.25rem, 1.1774rem + 0.3226vw, 1.5rem);
    line-height: clamp(1.25rem, 1.1774rem + 0.3226vw, 1.5rem);
  `,

  from20to16: css`
    font-size: clamp(1rem, 0.93rem + 0.32vw, 1.25rem);
    line-height: clamp(1.5rem, 1.43rem + 0.32vw, 1.75rem);
  `,

  from16to14: css`
    font-size: clamp(0.88rem, 0.84rem + 0.16vw, 1rem);
    line-height: clamp(1.25rem, 1.18rem + 0.32vw, 1.5rem);
  `,

  from14to10: css`
    font-size: clamp(0.625rem, 0.5524rem + 0.3226vw, 0.875rem);
    line-height: clamp(0.625rem, 0.5524rem + 0.3226vw, 0.875rem);
  `,
};
