import { css } from '@emotion/react';
import { button } from '@styles/button.styles';
import { breakpoints } from '@styles/variables.styles';

const base = css`
  position: sticky;
  top: 0;
  padding: 0 20px;
  height: 56px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  z-index: 12;
  background-color: var(--color-background-1);
  max-height: 80px;
  font-family: var(--font-family-primary);
  border-bottom: 1px solid #f8f8f80d;

  @media ${breakpoints.fromMed} {
    max-width: 100%;
    padding: 16px 20px;
    align-items: center;
  }
`;

const logo = css`
  display: block;
  margin-top: 2px;
  cursor: pointer;
  flex-shrink: 0;
  width: 80px;
  height: 19px;
`;

const location = css`
  display: block;

  @media ${breakpoints.fromMed} {
    display: none;
  }
`;

const navigation = css`
  display: none;

  @media ${breakpoints.fromLrg} {
    display: flex;
    size: 16px;
    line-height: 20px;
  }
`;

const navigationItem = css`
  color: var(--color-text-5);
  transition: color 0.2s ease-out;
  padding: 6px 16px;
  font-size: 20px;
  line-height: 28px;
  min-width: max-content;

  :hover {
    color: var(--color-general-primary);
  }

  @media ${breakpoints.fromLrg} {
    padding: 16px;
    font-size: 14px;
    line-height: 20px;
  }
`;

const navigationItemActive = css`
  ${navigationItem};
  color: var(--color-text-6);
  font-family: var(--font-family-primarySemiBold);
`;

const navigationList = css`
  @media ${breakpoints.fromMed} {
    display: flex;
    justify-content: space-between;
    color: white;
    font-weight: 400;

    li {
      position: relative;
      overflow: hidden;
    }

    a {
      position: relative;
      z-index: 1;
      display: inline-block;
      padding-bottom: 8px;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: white;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 0.3s ease-out;
      }

      &:hover::before {
        transform: scaleX(1);
      }
    }
  }
`;

const activeLink = css`
  @media ${breakpoints.fromMed} {
    color: var(--color-general-primary);
  }
`;

const authenticationLink = css`
  ${button.outline};
  display: inline-flex;
  padding: 4px 16px;
  font-family: var(--font-family-primarySemiBold);
  font-size: 14px;
  line-height: 20px;
  border-radius: 32px;
  min-width: max-content;

  @media ${breakpoints.fromMed} {
    padding: 4px 20px;
  }
`;

const hamburger = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 16px;
  width: 20px;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  z-index: 3;

  @media ${breakpoints.fromMed} {
    display: none;
  }
`;

const hamburgerActive = css`
  ${hamburger};

  span:nth-of-type(1) {
    transform: translate(5px, 10px) rotate(45deg);
  }
  span:nth-of-type(2) {
    opacity: 0;
  }
  span:nth-of-type(3) {
    transform: translate(4px, -5px) rotate(-45deg);
  }
`;

const line = css`
  height: 1px;
  background-color: #f4f8f7;
  transition: all 0.3s ease-out;
`;

const linePrimary = css`
  ${line}
  width: 100%;
`;

const lineSecondary = css`
  ${line}
  width: 65%;
  align-self: flex-end;
`;

const adminSection = css`
  display: flex;
`;

const dashboard = css`
  position: relative;
  border-bottom: 1px solid #505050;
`;
const languageBtn = (isActive: boolean) => css`
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: var(--color-background-2);
  border: none;
  padding: 8px 12px;
  border-radius: 28px;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 12px;
  transition: all 0.2s ease-out;

  span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }

  &:hover,
  &:focus {
    background-color: #2a282f;
    transition: all 0.2s ease-out;
  }

  @media ${breakpoints.fromMed} {
    padding: 8px 16px;
  }

  ${isActive &&
  css`
    background-color: #2a282f;
    transition: all 0.2s ease-out;
  `}
`;

const logout = css`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 12px;
  color: #f37c6c;

  &:hover,
  &:focus {
    color: white;

    &:after {
      opacity: 1;
    }

    & path {
      fill: white;
    }
  }
`;

const partner = css`
  ${button.primary};
  display: inline-flex;
  padding: 4px 16px;
  background: linear-gradient(89.8deg, #aeee39 -8.45%, #62f467 109.75%);
  font-family: var(--font-family-primarySemiBold);
  font-size: 14px;
  line-height: 20px;
  border-radius: 32px;
  min-width: max-content;

  @media ${breakpoints.fromMed} {
    padding: 4px 20px;
  }

  &:hover,
  &:focus {
    color: black;
    opacity: 1;
  }
`;

export const styles = {
  base,
  location,
  hamburger,
  hamburgerActive,
  linePrimary,
  lineSecondary,
  navigation,
  navigationList,
  activeLink,
  authenticationLink,
  logo,
  adminSection,
  logout,
  navigationItem,
  navigationItemActive,
  dashboard,
  partner,
  languageBtn,
};
