import { css } from '@emotion/react';

/**
 * Display grid
 */

const basic6col = css`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 20px;
  grid-auto-rows: min-content;
  max-width: 1600px;
  padding: 0 20px;
`;

const basic10col = css`
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: 40px;
  padding: 0 20px;
`;

const basic12col = css`
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 40px;
  padding: 0 20px;
`;

const basic24col = css`
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(24, 1fr);
  grid-column-gap: 24px;
  padding: 0 20px;
`;

const basic40col = css`
  display: grid;
  grid-template-columns: repeat(40, 1fr);
  grid-auto-rows: min-content;
  margin: 0 auto;
  max-width: 1920px;
`;

const basic46col = css`
  display: grid;
  grid-template-columns: repeat(46, 1fr);
  grid-auto-rows: min-content;
  margin: 0 auto;
  max-width: 1600px;
`;

const basic48col = css`
  display: grid;
  grid-template-columns: repeat(48, 1fr);
  grid-auto-rows: min-content;
  margin: 0 auto;
  max-width: 1600px;
  grid-column-gap: 0;
`;

const basic50col = css`
  display: grid;
  grid-template-columns: repeat(50, 1fr);
  grid-auto-rows: min-content;
  margin: 0 auto;
  max-width: 1600px;
  grid-column-gap: 0;
`;

export const grid = {
  basic6col,
  basic10col,
  basic12col,
  basic24col,
  basic40col,
  basic46col,
  basic48col,
  basic50col,
};
